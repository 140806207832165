//color of zealiums
$orange:#ff7400;
$darkorange:#ff4d00;
$lightorange: #ff9a00;

$green:#a1bf34;
$blue:#059197;
$gray:#767575;
$graylight:#545454;
$graymiddle:#262626;
$graydark:#0c0c0c;

@keyframes fullchange{
    0% {
//        clip-path: polygon(0% 0%, 0 100%, 100% 100%, 100% 0);
        opacity: 1
    }
    100%{
//        clip-path: polygon(0 0, 0 100%, 0 100%, 0 0);
        opacity: 0;
    }
}
@keyframes wowwow{
    0%{
        transform: rotate(0deg);
    }
    15%{
        transform: rotate(14deg);
    }
    30%{
        transform: rotate(-19deg);
    }
    60%{
        transform: rotate(2deg);
    }
    90%{
        transform: rotate(-2deg);
    }
    100%{
        transform: rotate(0deg);
    }
}
//global declarations
.orange{
    color: $orange !important;
}
.darkorange{
    color:$darkorange;
}
.green{
    color: $green;
}
.blue{color: $blue}
.bg-green{background-color:$green;}
.bg-orange{background-color: $orange!important;}
.bg-blue{background-color: $blue}
.btn-outline-warning {
    color:white;
    background-color: transparent;
    background-image: none;
    border-color: #f38720;
}
.btn-outline-warning:hover {
    color: white;
    background-color: $orange;
    border-color: $orange;
}

body{
    height: 100%;
    font-family: 'Catamaran', sans-serif!important;
    font-size: 14px!important;
    
}
p{
    font-family: 'Raleway', sans-serif;
}
section{padding: 2.3rem 0}
section h2{padding-bottom: 2rem;font-size: 2.1rem;text-transform: uppercase;text-align: center}

 .content{
        padding: 5% 8% 10%;
    }
.boxshadow{
   box-shadow: 1px 24px 34px 2px rgba(145,135,145,1);
}
%banners{
    background: url(../images/banner_home.jpg) center center no-repeat;
    background-size: cover;
    height: 70vh;
}
#startzealium{
    @extend %banners;    
    height:100vh; position:relative;z-index:1;background-color:white;
}
#startzealiumabout{
    @extend %banners;
    background-image: url(../images/about/banner_about.jpg); position:relative;z-index:1;background-color:white;
}
#startzealiumcareer{
    @extend %banners;
    background-image: url(../images/career/banner_career.jpg); position:relative;z-index:1;background-color:white;
}
#startzealiumblogs{
    @extend %banners;
    background-image: url(../images/blogs/banner_blogs.jpg); position:relative;z-index:1;background-color:white;
}
#startzealiumcontact{
    @extend %banners;
    background-image: url(../images/banner_contactus.jpg); position:relative;z-index:1;background-color:white;
}
#startzealiumwebdevelopment{
     @extend %banners;
    background-image: url(../images/web_development/banner.jpg);  position:relative;z-index:1;background-color:white;
}
#startzealiumelearning{
     @extend %banners;
    background-image: url(../images/elearning/banner.jpg);  position:relative;z-index:1;background-color:white;
}
#startzealiumcorporate{
     @extend %banners;
    background-image: url(../images/corporatei/banner.jpg);  position:relative;z-index:1;background-color:white;
}
#startzealiumportfolio{
     @extend %banners;
    background-image: url(../images/portfolio/portfolio_bg.jpg); position:relative;z-index:1;background-color:white;
    height: 100%;
}
#privacypolicyzealium{
    background-image: linear-gradient($orange,$darkorange);
    background-size: cover;
    height: 40vh;
    z-index: 1;
    position: relative;
}
main{
    position: relative;
    z-index: 1;
    background-color: white;
}
.zealium-navbar{
    background-color:transparent;
    padding: 1rem;
   font-size: 1.1rem;
    transition: all .7s;
    a{
        color:white;
    }
   
}
.navbar-dark .navbar-nav .nav-link {
    color: rgba(255,255,255,1);
}
.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
    color: $orange;
}
.zealium-navbarfixed{
    background-color: white;
    background-size:200% 100%;
    background-position: left bottom;
    color: black!important;
    padding: 1rem;
    font-size: 1.1rem;   
    transform: all .7s;
    box-shadow: 10px 0px 15px #666
   
    
}
.zealium-navbarfixed #contactus{
    border:1px solid $orange !important;
    
}
.zealium-navbarfixed #contactus> a{
    color: $orange!important;
}

.zealium-navbarfixed a.nav-link {
    color: black!important ;
    transition: all .7s  ease
}

.dropdown-divider{
    margin:0!important;
//    display: none;
}
    .dropdown-menu{
        border: none!important;
        margin: none!important;
    }
#bannertext{
    transform: translateY(35%);
   
}
.banner1,.banner2,.banner3{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 100vh;
    display: flex;
}
.banner1{
    background-image: url(../images/banner1.jpg);
}
.banner2{
    background-image: url(../images/banner2.jpg);
}
.banner3{
    background-image: url(../images/banner3.jpg);
}
.banner-text{
  text-align: center;
    color: white;
   margin: auto;
}
#startzealium121 .owl-controls {
		position: absolute;
		top: auto!important;
    bottom: 1% !important;
    left: 47%!important;
}
.owl-buttons{
    div{
        color: white;
        opacity: 1!important;
        background-color: rgba(255, 255, 255, 0.19)!important;
        transition: all .5;
        font-size: 20px!important;
    }
    div:hover{
        background-color: $gray!important;
        transform: translateY(-5px);
        transition: all .4s 
    }
}
.banner-caousel h1{
//    font-size: 4rem;
    font-weight: 500;
    line-height: 1.3;
    text-align:center;
    font-size: 3rem;
    text-transform: uppercase;
   
}
.btn-zealium{
    background-color: rgba(255, 255, 255, 0.7);
    border-radius:0;
    color: black;    
}
.social-zealium{
    list-style: none;
    color: white;
    border-radius: 50%;
    padding:9px 15px 9px 15px;
    background-color: #141414;
}
.social-zealium:hover{
    color: $orange;
}
.navbar-dark .navbar-nav .active > .nav-link,.navbar-dark .navbar-nav .nav-link.active{
    border-bottom: 2px solid white;
    position: relative;
}
.zealium-navbarfixed .active  {
    border-bottom: 2px solid $orange;
    position: relative;
}
.zealium-navbarfixed .nav-dot::after{
    position: absolute;
    width: 4px;
    height:4px;
    bottom:-3px;
    right: 0%;
    content: "";
    border-radius: 50%;
    background-color: $orange;
}

.nav-dot{
    position: relative;
  
}
.nav-dot::after{
     position: absolute;
    width: 4px;
    height:4px;
    bottom:-1px;
    right: 0%;
    content: "";
    border-radius: 50%;
    background-color: white;
    
}
.clip-box1{   
  box-shadow: 2px 10px 33px -15px rgba(0,0,0,0.75);
    background-color:$lightorange;
    transition: all .4s ease;
}
.clip-box2{   
  box-shadow: 2px 10px 33px -15px rgba(0,0,0,0.75);
   background-color:$orange; 
    transition: all .4s ease;
}
.clip-box3{   
  box-shadow: 2px 10px 33px -15px rgba(0,0,0,0.75);
    background-color:$darkorange;
    transition: all .4s ease;
}
.normalclip-box1{   
  box-shadow: 2px 10px 33px -15px rgba(0,0,0,0.75);
    background-color:$lightorange;
    transition: all .4s ease;
}
.normalclip-box2{   
  box-shadow: 2px 10px 33px -15px rgba(0,0,0,0.75);
   background-color:$orange; 
    transition: all .4s ease;
}
.normalclip-box3{   
  box-shadow: 2px 10px 33px -15px rgba(0,0,0,0.75);
    background-color:$darkorange;
    transition: all .4s ease;
}
.clip-box1:hover, .clip-box2:hover, .clip-box3:hover{
    background-color: white;  
}

.clip-inner{
    clip-path: polygon(88.57% -2px, 100.86% 16.56%, 100% 100%, 50% 100%, 0px 100%, 0px 0px);
    background-color: white;
    padding: 3rem;
    transition: all .4s ease;
}
.clip-inner-4{
    clip-path: polygon(88.57% -2px, 100.86% 16.56%, 100% 100%, 50% 100%, 0px 100%, 0px 0px);
    background-color: white;
    padding: 1.1rem;
    transition: all .4s ease;
}
%icons{
    background-position:center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height:73px;
   margin: 0rem 3.1rem;
}
.homeicon1{
     @extend %icons;
    background-image: url(../images/home-icon1.png);   
}
.clip-box1:hover .homeicon1{background-image:url(../images/home-icon1_hover.png); }
.homeicon2{
    @extend %icons;
    background-image: url(../images/home-icon2.png);
}
.clip-box2:hover .homeicon2{background-image:url(../images/home-icon2_hover.png); }
.homeicon3{
    @extend %icons;
    background-image: url(../images/home-icon3.png);
}
.careericon1{
    @extend %icons;
    background-image: url(../images/career/Opensource-Web-Developer-Experience.svg);
    
    
}
.careericon2{
    @extend %icons;
    background-image: url(../images/career/Graphic-Designer-Trainee.svg);
    
    
}
.careericon3{
    @extend %icons;
    background-image: url(../images//career/Business-Development-Executive.svg);
    
    
}
.careericon4{
    @extend %icons;
    background-image: url(../images//career/content-writer.svg);
     
    }

.clip-box3:hover .homeicon3{background-image:url(../images/home-icon3_hover.png); }

.clip-box1:hover .clip-inner{
    background-color: $lightorange;
    color: white;
}
.clip-box2:hover .clip-inner{
    background-color: $orange;
    color: white;    
}
.clip-box3:hover .clip-inner{
    background-color: $darkorange;
    color: white;
}

#hometestimonial{
    @extends icons;
    background-image:url(../images/testimonial_bg.png);
    background-size: cover;
    background-position: top left;
}

.carousel-inner {
    position: relative;
    width: 73%;
    overflow: hidden;  
    margin-left: 6%;
}    
.slide-wrapper{display:inline;}
.slide-wrapper .container{padding:0;}


/*------------------------------ vertical bootstrap slider----------------------------*/

.carousel-inner> .carousel-item.carousel-item-next ,
.carousel-inner > .carousel-item.active.carousel-item-right{ 
    transform: translate3d(0, 100%, 0); -webkit-transform: translate3d(0, 100%, 0); -ms-transform: translate3d(0, 100%, 0); -moz-transform: translate3d(0, 100%, 0); -o-transform: translate3d(0, 100%, 0);  top: 0;
    
}
.carousel-inner > .carousel-item.carousel-item-prev ,
.carousel-inner > .carousel-item.active.carousel-item-left{ 
    transform: translate3d(0,-100%, 0); -webkit-transform: translate3d(0,-100%, 0);  -moz-transform: translate3d(0,-100%, 0);-ms-transform: translate3d(0,-100%, 0); -o-transform: translate3d(0,-100%, 0); top: 0;
    
}
.carousel-inner > .carousel-item.next.carousel-item-left ,
.carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right ,
.carousel-inner > .carousel-item.active{
    transform:translate3d(0,0,0); -webkit-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);; -moz-transform:translate3d(0,0,0); -o-transform:translate3d(0,0,0); top:0;
    
}

/*------------------------------- vertical carousel indicators ------------------------------*/
.carousel-indicators{
    position:absolute;
    top:128px;
    bottom:0;
    margin:auto;
    height:20px;
    right:auto; left:7px;
    width:auto;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}
.carousel-indicators li{
    display:block; margin-bottom:5px; border:1px solid $orange; 
    
}
.carousel-indicators li.active{
    margin-bottom:5px; background:$orange;
    
}
.testimonialclip{
    clip-path: polygon(17.59% 0, 103.34% 0, 100% 100%, 50% 100%, 0px 100%, 0px 76px);
}


//footer rules
.scroll-to-top{
 display: none;
left: 1%;

bottom: 2%;

right: auto;

padding: 5px 13px;

position: fixed;

z-index: 99;

background-color: white;

font-size: 1.5rem;

border-radius: 2px;

color: $orange;

box-shadow: 0px 0px 2px $gray;
    opacity: 0.8;
    border: none!important;
    transition: all .4s;
}
.scroll-to-top:hover i{
    transform: translateY(-3px);
    transition: all .4s;
}
#Contactusfooter{
background-color: #ececec;
clip-path: polygon(93.75% 0, 100% 37.66%, 100% 100%, 50% 100%, 0px 100%, 0px 0px);
color: $graydark;
transition: all .3s;
}
.clip-footer{
background-color: $graydark;
    padding-top: 0;
    position: relative;
    z-index: 1;
    
 
}
#Contactusfooter:hover{
  clip-path:polygon(93.75% 0px, 99.87% 49.2%, 93.60% 100.51%, 50% 100%, 0px 100%, 0px 0px);
}
.top-layer{
   margin-bottom: 30.2rem;
}
.footer{
    background: url(../images/footer_bg.jpg) center center no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: white;
    position:fixed;
    bottom: 0;
    top: auto;
    left: 0;
    right: auto;
    z-index: 0;
    max-width: 100%;
    width: 100%;
    max-height: 95%;
    padding: 0;
    a{
        z-index: auto;
    }
  
}
.footer h4{
    padding: .6rem 0;
    
}
.footer ul{
    padding-top: 1.5rem;
    list-style: none;
    padding-left: 0;
   
    a{
        color: white;
    }
    a:hover{
        color: rgba(255, 255, 255, 0.7);
    }
    
}
.footer-slab{
    background-color: #141414;
    height: 97%;
    box-shadow: 1px 1px 11px black;
    .border-y{
            border-top: 1px solid white;
            border-bottom: 1px solid white;
        }
    ul>li{
        
        img{
            margin-right: 40px;
        }
        a{
            font-size: 1.4rem;
        }
    }
    h3{
        margin-top: 1.5rem;
    }
    
}
.services-expand .box{
    width: 100%;
    position: relative;
    transition: all 0.3s ease-out 0s; 
    text-align: center;
    .box-title{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width:100%;
        text-align: center;
    }
}
.services-expand{
   
    .box::before{
        content: "";
        display: block;
        padding-top: 70%;
    }
    
    .b-1{
        .box-expand{        
        top: 0;
        right:0;
        left: 0;
        bottom: 0;
        color: white;
        background-color: $orange;
        }
    }
    .b-2{
        .box-expand{
        top: auto;
        right:auto;
        left: 0;
        bottom:0;
        background-image: url(../images/expand-elearning.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: white;
            
        }
        
    }
    .b-3{
        .box-expand{
        top: 0;
        right:0;
        left: 0;
        bottom:0; 
        background-image: url(../images/expand-productdev.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: white;
        }
        
    }
    .b-4{
        .box-expand{
        top: auto;
        right:0;
        left: auto;
        bottom:0;
        color: white;
        background-color: $orange;
        }
        
    }
    .b-5{
        .box-expand{
        top: 0;
        right:0;
        left: auto;
        bottom:0; 
        color: white;
        background-image: url(../images/expand-testing.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: white;
        }
        
    }
    .b-6{
        .box-expand{
        top: auto;
        right:0;
        left: auto;
        bottom:0; 
         background-image: url(../images/expand-coorporate.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: white;   
        }
        
    }
}
.box-expand{
        position: absolute;        
        width: 100%;
        height: 100%;
        z-index: 9;
        transition: all 0.4s ease-out 0s;
        overflow: hidden;
        p{
            display: none;
        }
    }
.box-expand:hover{
        
        width: 200%;
        height:200%;
        z-index: 99;
        p{
            display: block;
            widows: 100%;
            overflow: hidden;
            padding: 0 14%;
        }
    }


//about Page

.santosh{
    position: absolute;
    top: -77px;
    left: 0;
}
.aboutcolorclip{
  
background: linear-gradient($orange, $darkorange);
   clip-path: polygon(0px 16%, 100% 0px, 99.93% 83.33%, 0% 100%);
    
    padding: 4.5rem 0;
}
.inner-aboutclip{
    
    text-align: center;
    p{
        background:url(../images/about/parallax.jpg);
        padding: 8rem;
        background-attachment: fixed;
        color: white;
        text-align: center;
        font-size: 1.4rem;
    }
    
}

#ourteambullets{
    
    ul{
        list-style: none;
        margin-left: 1rem;
        font-size: 1.4rem;
        li{
            margin: 1.2rem 0rem;
        }
    }
    
      li:nth-child(1) i{        
           color:$lightorange;
           padding-left: 2.4rem * 1;     
    }
       li:nth-child(2) i{        
           color:$orange;
           padding-left: 2.4rem * 2 ;     
    }
       li:nth-child(3) i{        
           color:$darkorange;
           padding-left: 2.4rem * 3 ;     
    }
       li:nth-child(4) i{        
           color:$gray;
           padding-left: 2.4rem * 4 ;     
    }
       li:nth-child(5) i{        
           color:$gray;
           padding-left: 2.4rem * 4 ;     
    }
       li:nth-child(6) i{        
           color:$darkorange;
           padding-left: 2.4rem * 3 ;     
    }
       li:nth-child(7) i{        
           color:$orange;
           padding-left: 2.4rem * 2 ;     
    }
       li:nth-child(8) i{        
           color:$lightorange;
           padding-left: 2.4rem * 1 ;     
    }
   
    
}
.aboutvalue-box{
    background: url(../images/about/our-value_bg2.jpg) center center no-repeat;
    background-size: 100% 100%;
    padding-bottom: 2.4rem;

}
#corvalueimage{
    transition: all .5s ease-out;
    transform: rotate(180deg);
}

//webdevlopment
#webexpertise{
    background: url(../images/web_development/our_expertise_bg.jpg) center bottom no-repeat;
    background-size: cover;
    color: white;
   text-align: center;
    .webexpertimg{
        width: 224px;
	height: 150px;
	text-align: center;
        margin-left: 26%
    }
}
.what-we-do-border1{
    border-top: 5px solid $orange!important;
   box-shadow: 0px 1px 31px -11px rgba(0,0,0,0.75);
    height: 100%
}
.what-we-do-border2{
    border-top: 5px solid $darkorange!important;
    box-shadow: 0px 1px 31px -11px rgba(0,0,0,0.75);
    height: 100%;
}
#web2{
    background: url(../images/web_development/parallax.jpg) top right no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding: 6% 0;
}
.ss{padding-top: 46%;
overflow: hidden}
.img-old,.img-new{
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.mouse-img{
    top: auto;
    left: auto;
    right:6%;
    bottom:3%;
    cursor: pointer;
    border-radius: 41px;
    box-shadow: 0 9px #999;
}
.mouse-img:active{
     box-shadow: 0 6px #666;
}

 .img-animation{
    animation: fullchange;
    animation-duration: 7s;
    animation-fill-mode: forwards;
    }
.img-animation-reverse{
    animation:fullchange;
    animation-duration: 7s;
    animation-fill-mode: forwards;
    animation-direction: reverse;
}


//corporate identity

.e-card1{background-color: $darkorange; height: 100%}
.e-card2{background-color: $graydark ;opacity: 0.92;height: 100%}
.expertise-images-position-fix{
    padding-right: 7rem;
    transition: all 5s;
    animation-name: wowwow;
    animation-duration: 5s;
    animation-timing-function: ease-in-out;
    animation-delay: 6s;
}
#corporateservice{
    
    clip-path: polygon(49% 9%, 100% 0px, 99.61% 89.98%, 50.13% 82.87%, 1px 91.65%, 0px 0px);
    background-color: #dddee2;
    margin-top: 3em;
}
.demobox1,.demobox2,.demobox3{
        border:1px solid $gray;
    .democlip{
        clip-path: polygon(81.71% 0px, 100% 25%, 100.86% 101.25%, 52.3% 101.25%, 0px 101.25%, 0px 0px);
    }
}
.demobox1{
  background-color: $lightorange;
}
.demobox2{
    background-color: $orange;
}
.demobox3{
    background-color: $darkorange;
}
#corporateexpertise{
    background:url(../images/corporatei/diary.png)  no-repeat;
//    background-position: right 2% bottom 8%;
    background-position: left top;
    padding-bottom: 10rem;
     margin-top: 3rem;
    h2{
        margin-top:6.2rem;
    }
}
.corporateexperbg{
    padding: 0 8rem ;
}


//ealearning
.owl-theme .owl-controls .owl-page.active span, {
    background: $orange;
    color: $orange;
}
.card-row{
    display:flex;
   height: 50vh;
    .instrec{display: flex;flex-direction: column;justify-content: center}  
    
}
.strec1{
    display: flex;
    background: linear-gradient(
        rgba(255, 154, 0, 0.6),rgba(255, 154, 0, 0.6)) , url(../images/elearning/expertise1.1.jpg) ;
    background-size:100% 100%;
    background-position: center left;
    background-repeat: no-repeat;
    background-attachment: fixed ,fixed;
    flex: 1 0 10em;
    overflow: hidden;
    padding: 1em;
    transition: flex-basis 700ms ease-in-out;
    p{display: none;transiion:width 1000ms ease-in-out;padding:2rem 4rem}
}
.strec2{
    display: flex;
    background:linear-gradient(
     rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.6)
    ), url(../images/elearning/expertise2.1.jpg) center center no-repeat;
    background-attachment: fixed;
    background-size: cover;
    flex: 1 0 10em;
    overflow: hidden;
    padding: 1em;
    transition: flex-basis 700ms ease-in-out;
    p{display: none;transiion:width 1000ms ease-in-out;padding:2rem 4rem}
}
.strec3{
    display: flex;
    background: linear-gradient(
     rgba(255, 77, 0, 0.6), rgba(255, 77, 0, 0.6)      
    ), url(../images/elearning/expertise3.1.jpg) center center no-repeat;
    background-size: cover;
    background-attachment: fixed;
    flex: 1 0 10em;
    overflow: hidden;
    padding: 1em;
    transition: flex-basis 700ms ease-in-out;
    p{display: none;transiion:width 1000ms ease-in-out;padding:2rem 4rem}
}
.sticker1,.sticker2,.sticker3,.sticker4,.sticker5,.sticker6,.sticker7,.sticker8{
      text-align: center;
      background-color: whitesmoke;
}

#learncopetence{}
#topcompotence{    
    position: relative;
    padding-top: 35%;
}
#bgcompotence{
    background-image: url(../images/elearning/bg.jpg);
    background-position: bottom;
}
.pin{position:absolute;
 top: 0;
 left: auto;
 bottom: auto;
 right: 0;
}

//portfolio
  #portfoliotabs{
    .nav-pills .nav-link.active{
        background-color: #ff740094!important;
    }
}

.contactbox{
    box-shadow: -3px 1px 29px -6px rgba(0,0,0,1);
    padding: 3rem 0;
}

#success-message {
  opacity: 0;
}

.col-xs-12.col-sm-12.col-md-12.col-lg-12 {
  padding: 0 20% 0 20%;
}

.margin-top-25 {
  margin-top: 25px;
}

.form-title {
  padding: 25px;
  font-size: 30px;
  font-weight: 300;

}

.form-group .form-control {
 
  border-bottom: 1px;
  border-style: none none solid none;
  border-radius:0; 
  border-color: #000;
}

.form-group .form-control:focus {
	box-shadow: none;
  border-width: 0 0 2px 0;
  border-color: $orange;
  
}

textarea {
  resize: none;
}

.btn-mod.btn-large {
    height: auto;
    padding: 13px 52px;
    font-size: 15px;
}

.btn-mod.btn-border {
    color: #000000;
    border: 1px solid #000000;
    background: transparent;
}

.btn-mod, a.btn-mod {
    
    box-sizing: border-box;
    padding: 4px 13px;
    color: #fff;
    background: rgba(34,34,34, .9);
    border: 1px solid transparent;
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 2px;  
    border-radius: 0;   
    box-shadow: none; 
    transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.btn-mod.btn-border:hover, .btn-mod.btn-border:active, .btn-mod.btn-border:focus, .btn-mod.btn-border:active:focus {
    color: #fff;
    border-color: $orange;
    background: $orange;
    outline: none;
}
.sfill{
    fill:$gray;
    max-width: 100%;
    color:$gray;
    
}
.sfill:hover{
    color: $lightorange;
    fill:$lightorange;
}

.border-popup-box{
    border-top: 6px solid $orange;
    border-bottom: 1px solid $orange!important;
}

@media only screen and (max-width: 500px) {
    .btn-mod.btn-large {
       padding: 6px 16px;
       font-size: 11px;
    }
  
    .form-title {
        font-size: 20px;
  }
    .col-xs-12.col-sm-12.col-md-12.col-lg-12 {
  padding: 0 7% 0 7%;
}
}
//contactus page

#contactuspageform{
    background: url(../images/form_img.jpg) top left no-repeat;
    background-size: 100%;
}
.frompadding{
    padding-left: 11%;
    padding-right: 11%;
}
.small-screen-menu{
    padding: 0!important;
}
.small-screen-border{
    border-top: 1px solid rgba(118, 117, 117, 0.2);
    padding-left: .8rem;
    background-color: rgba(175, 175, 175, 0.11)
   ;
}

/*
MEDIA QUERIES STARTS HERE 
_______________________________
*/

// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) { 
    .ss{padding-top: 89%;}
}
@media(min-width:577px) and (max-width:766px){
      .ss{padding-top: 47%;}
    .mouse-img{
        width: 32px;
        bottom: 17%;
        right: 0;
    }
}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) { 
   
     .banner-caousel h1{ font-size: 1.7rem;}
    section h2{padding-bottom: 1rem;font-size: 1.6rem;}
    h4,h3{font-size:1.2rem;}
        .carousel-inner {
        position: relative;
        width: 100%;
        overflow: hidden;  
        margin-left: 0;
    }
      .carousel-indicators {
        position: relative;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 20px;
        right: auto;
        left: 7px;
        width: auto;
        -webkit-transform: rotate(90deg);
        transform: rotate(0deg);
    }
    .box-expand{
        position: absolute;        
        width: 100%;
        height: 100%;
        z-index: 9;
        transition: all 0.4s ease-out 0s;
        overflow: hidden;
        p{
            display: block;
        }
    }
    .box-expand:hover{
        
        width: 100%;
        height:200%;
        z-index: 99;
        p{
            display: block;
            overflow: hidden;
            padding:0 14%;
        }
    }
    #startzealium121 .owl-controls {
    left: 42%!important;
}
    .ftrbr{
        display: none;
    }
    
    //about page
    .santosh{
        margin-top: 40px;
    }
    .reveal-at-small{
        padding-top: 10rem;
    }
    .inner-aboutclip{
    text-align: center;
    p{       
        padding: 3.9rem;
        font-size: 1rem;
        background-attachment: fixed;
    }
    
}
    #corporateexpertise{    
     margin-top: -2rem;
    
}
    
    #ourteambullets ul {

    list-style: none;
    margin-left: -2rem;
    font-size: 0.9rem;

}
    
    #webexpertise .webexpertimg {
    margin-left: 19%;
}
    #web2{
    background: url(../images/web_development/parallax.jpg) top center no-repeat;
}
    //corporate identity
    #corporateservice{
    clip-path: polygon(49% 9%, 100% 0px, 99.74% 100.19%, 50.92% 100.13%, 3px 100%, 0px 0px);
}
   #corporateexpertise{
    background:none;
//    background-position: right 2% bottom 8%;
    background-position: left top;
    padding-bottom: 2rem;
    
//    background-size: cover;
//    padding: 3rem .5rem 13rem 3rem; 
       h2{
        margin-top:0rem;
    }
}
    .corporateexperbg{
    padding: 0 ;
}
    .mouse-img {
    top: auto;
    left: auto;
    right: 43%;
    bottom: 10%;
    cursor: pointer;
    border-radius: 41px;
    box-shadow: 0 9px #999;
    width: 35px;
}
    .expertise-images-position-fix{
    padding-right:0;
}
    
    //elaearning
    .card-row{
    display:flex;
    flex-direction: column;
   height: 110vh;
    .instrec{display: flex;flex-direction: column;justify-content: center;}  
    
}
    
    .strec1,.strec2,.strec3{
        overflow: hidden;
        margin-top: 1rem;
        padding: 1rem;
        color: white;
        
        background-attachment:fixed;
        p{
            display: block;
            padding: 1rem
        }
    }
    #bgcompotence {
	background-image:none;
	background-position: bottom;
}
    #topcompotence {
	position: relative;
	padding-top: 6%;
}
    #hometestimonial{
    @extends icons;
  
    background-position: top center;
}    
    .careericon1,.careericon2,.careericon3{
        height:26vh;
        padding: 0;
    }
    
    //footer position
    .top-layer{
    z-index: 2;
    margin-bottom: 0rem;
}
    .frompadding {
    padding-left:15px;
    padding-right: 15px;
}
.footer{
    position:relative;
    bottom: 0;
    top: auto;
    left: 0;
    right: auto;
    z-index: 2;
    max-width: 100%;
    width: 100%;
    max-height: 95%;
}
}

// Large devices (desktops, 992px and up)
@media (max-width: 992px) { 

}

// Extra large devices (large desktops, 1200px and up)
@media (max-width: 1200px) {
  
}

// Extra small devices (portrait phones, less than 576px)
//@media (max-width: 575.98px) {  }

// Small devices (landscape phones, 576px and up)
//@media (min-width: 576px) and (max-width: 767.98px) { }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) { 
    .homeicon1, .homeicon2, .homeicon3 { 
        height: 102px;    
        margin: 0rem 4.1rem;
    }
    .careericon1,.careericon2,.careericon3{
        height:26vh;
        padding: 0;
    }
    .clip-inner{
    
    padding: 1rem;
   
}
}

// Large devices (desktops, 992px and up)
//@media (min-width: 992px) and (max-width: 1199.98px) {  }

// Extra large devices (large desktops, 1200px and up)
//@media (min-width: 1200px) {  }

@media (min-width: 992px){
    
    .navbar-expand-md .navbar-nav .dropdown-menu {
    background-color: rgba(0, 0, 0, 0.71);
    color: white;
    a{
        padding: 10px 79px 14px 12px;
    }
    margin-top: 18px;
}
.dropdown-item{
    color: white!important;
}
.dropdown-item:focus, .dropdown-item:hover {
    color: white;
    text-decoration: none;
    background-color: $orange;
}

     .box-expand:hover{
        padding: 0px;
    }
    
    %sticker{
      position: absolute;
      top: 10%;
      left: 0;
      bottom: 0;
      right: 0;
      text-align: center;
      padding: 0 2rem;
      
//      display: inline-flex;
//      flex-direction: column
      
    }
    
    .sticker1,.sticker2,.sticker3,.sticker4,.sticker5,.sticker6,.sticker7,.sticker8{
        @extend %sticker;
        box-shadow: 0px 4px 17px -8px rgba(0,0,0,0.75);
        background-color: white;
        h4{
            font-size: 1rem;
            color: $darkorange;
        }
        transition: all .4s ease-out;
    }
    
    .sticker1{
        left: 10%;
        bottom: auto;
        right: auto;
        transform:rotate(-12deg);
       
        .pin{
                position:absolute;
                 top: 0;
                 left: auto;
                 bottom: auto;
                 right: 0;
            }
    }    
    .sticker2{
     left:30%;
     bottom: auto;
     right: auto;   
     transform:rotate(8deg);
        .pin{
                position:absolute;
                 top: 0;
                 left: -18px;
                 bottom: auto;
                 right: auto;
            }
    }
    .sticker3{
     left:auto;  
     bottom: auto;
     right:31%;
     transform:rotate(-5deg); 
        .pin{
                position:absolute;
                 top: 0;
                 left: auto;
                 bottom: auto;
                 right: -17px;
            }
    }
    .sticker4{
      left:auto;
      bottom: auto;
      right:10%;
      transform:rotate(6deg); 
        .pin{
                position:absolute;
                 top: 0;
                 left: auto;
                 bottom: auto;
                 right: -13px;
            }
    }
    .sticker5{
        top: auto;
        right: auto;
        left: 10%;
        bottom: 0%;
        transform:rotate(10deg); 
        .pin{
                position:absolute;
                 top: 0;
                 left: -17px;
                 bottom: auto;
                 right: auto;
            }
    }
    .sticker6{
      top: auto;
      right: auto;
      left:30%;
      bottom: 0%;
      transform:rotate(-8deg);  
        .pin{
                position:absolute;
                 top: 0;
                 left: auto;
                 bottom: auto;
                 right: 0;
            }
    }
    .sticker7{
      top:auto;
      left: auto;
      right: 31%;
      bottom: 0%;    
      transform:rotate(10deg);  
        .pin{
                position:absolute;
                 top: 0;
                 left: -17px;
                 bottom: auto;
                 right: auto;
            }
    }
    .sticker8{
      top: auto;
      left: auto;
      right: 10%;
      bottom: 0%;
      transform:rotate(-5deg);  
        .pin{
                position:absolute;
                 top: 0;
                 left: auto;
                 bottom: auto;
                 right: 0;
            }
    }
    
    .sticker1:hover,.sticker2:hover,.sticker3:hover,.sticker4:hover,.sticker5:hover,.sticker6:hover,.sticker7:hover,.sticker8:hover,{
        transform: rotate(0deg);
    }
    %strechover{
        flex-basis: 46rem;
    color: white;
    transition: all ease-out .5s;
     
    }
    
    .strec1:hover{
         @extend %strechover;
         background: linear-gradient(
        rgba(255, 154, 0, 0.9),rgba(255, 154, 0, 0.9)) , url(../images/elearning/expertise1.1.jpg) ;
        p{display:block;text-align: center;transition: width 1000ms ease-in-out}
    }
    .strec2:hover{
         @extend %strechover;
        background: linear-gradient(
     rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)      
    ), url(../images/elearning/expertise2.1.jpg) center center no-repeat;
        p{display:block;text-align: center;transition: width 1000ms ease-in-out}
    }
    .strec3:hover{
        @extend %strechover;
        background: linear-gradient(
     rgba(255, 77, 0, 0.9), rgba(255, 77, 0, 0.9)      
    ), url(../images/elearning/expertise3.1.jpg) center center no-repeat;
     p{display:block;text-align: center;transition: width 1000ms ease-in-out}
    
}
    .testiboxsize{
    margin: 0 4rem 0rem;
    }
    .shiftleft2,.shiftleft1{
        transform: translateX(27%);
    }
    .carousel-indicators{
        left:10%
    }
    #portfoliotabs{
    .nav-pills .nav-link.active{
        background-color: transparent!important;
        .imgdiv{
            transform: scale(1.5);
        }
    }
}
    
    
}


